import React, { useState,useContext } from 'react'
import {Context} from "./Wrapper";
import LV from '../img/LV.png';
import RU from '../img/RU.png';
import EN from '../img/EN.png';

export default function LangBar() {

  const context = useContext(Context);
              
  return (
    <div className='Language__content'>
      <button value='lv' style={{opacity: context.locale === 'lv' ? '1' : '.2'}} onClick={(e)=> context.selectLanguage(e)} type="button" ><img src={LV} alt="lv"/></button>
      <button value='ru' style={{opacity: context.locale === 'ru' ? '1' : '.2'}} onClick={(e)=> context.selectLanguage(e)} type="button"><img src={RU} alt="ru"/></button>
      <button value='en' style={{opacity: context.locale === 'en' ? '1' : '.2'}} onClick={(e)=> context.selectLanguage(e)} type="button"><img src={EN} alt="en"/></button>
    </div>
  )
}







