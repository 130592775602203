import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import LangBar from "./LangBar";
import {Context} from "./Wrapper";
import LV from '../img/LV.png';
import RU from '../img/RU.png';
import EN from '../img/EN.png';
import Logo from '../img/companyLogo.png';

const Nav = styled.nav`
position: fixed;
width:100%;
height:75px;

  padding: 0 20px;
  
  min-height: 9vh;
  background: var(--body-color);
  z-index: 10000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 960px) {
    justify-content: space-between;
    position: fixed;
    z-index: 10000;
    width: 100%;
  }
 
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;
  z-index: 10000;
  
  li {
    margin: 0px 20px;
    
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

const Item = styled.li`

`;

const StyledLink = styled(Link)`
  
  
  text-decoration: none;
  font-family: var(--body-font);
  color: var(--first-color);
  font-size: var(--h2-font-size);
  font-weight: --font-semibold;
  transition: all 0.3s;
  
  ::after {
    content: '';
    width: 0px;
    height: 2px;
    display: block;
    background: var(--third-color);
    opacity: 0.9;
    transition: 300ms;
  }
  
  :hover::after {
    width: 100%;
  }

  @media (max-width: 960px) {
    color: var(--third-color);
    // display: none
  }
`;

const Icon = styled.div`
  display: flex;
  gap: 20px;

  @media (min-width: 960px) {
    display: none;
  }
`;

const NavIcon = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  outline: none;

  @media (min-width: 960px) {
    display: none;
  }
`;

const LogoImg = styled.img`
  width: 87px;
  height: 82px;
`;

const LangSelect = styled.div`
  select {
    // disable default appearance
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color:var(--first-color);
    background-image: url(~"data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23ffffff%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius:4px;
    border:none;
    color: var(--third-color);
    font-weight:700;
    font-size:var(--h2-font-size);
    padding: 10px 10px 10px 10px;
    
  }
  select::-ms-expand { display: none };
  @-moz-document url-prefix() {
    select {
      color: rgba(0,0,0,0);
      text-shadow: 0 0 0 #ffffff;
    }
  }

  }
  @media (min-width: 960px) {
    display: none;

  }
`;

const Line = styled.span`
  display: block;
  border-radius: 50px;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: var(--first-color);
  transition: width 0.4s ease-in-out;
  
  :nth-child(2) {
    width: ${props => (props.open ? "40%" : "70%")};
  }
  :nth-child(3) {
    width: ${props => (props.open ? "40%" : "70")};
  }
`;

const Overlay = styled.div`
  position: fixed;
  // margin-top:100px;
  top:0;
  left:0;
  height: 100vh; 
  clip-path: ${props => (props.open ? "circle(132.1% at 94% 7%)" : "circle(0% at 100% 0%)")};
  -webkit-clip-path: ${props => (props.open ? "circle(132.1% at 94% 7%)" : "circle(0% at 100% 0%)")};
  z-index: ${props => (props.open ? "100" : "0")};
  width: 100vw;
  background: var(--first-color);
  transition: height 0.4s ease-in-out,  clip-path 0.4s ease-in-out, -webkit-clip-path 0.4s ease-in-out;

  @media (min-width: 960px) {
    display: none;
  }
`;
// ${props => (props.open ? "91vh" : 0)};
const OverlayMenu = styled.ul`
  list-style: none;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  display: ${props => (props.open ? "static" : "none")};

  li {
    opacity: ${props => (props.open ? 1 : 0)};
    font-size: 25px;
    margin: 50px 0px;
    transition: opacity 0.4s ease-in-out;
  }

  li:nth-child(2) {
    margin: 50px 0px;
  }
`;

const Navigation = (props) => {
  const [toggle, toggleNav] = useState(false);

  const context = useContext(Context);

  useEffect(() => {
      document.body.style.overflow = toggle ? 'hidden' : 'scroll'

  }, [toggle])

  return (
    <>
    
      <Nav open={toggle} >
          <Link to='/' >
          <LogoImg src={Logo} alt="" />
          </Link>
          <Menu>
            <StyledLink to='/#education-for' style={{margin: 'auto 15px'}}>
              <FormattedMessage
                      id = "navigation.1.button"
                      defaultMessage="Home"
              />
            </StyledLink>
            <StyledLink to='/#faq' style={{margin: 'auto 15px'}}>
              <FormattedMessage
                            id = "navigation.2.button"
                            defaultMessage="FAQ"
              />
            </StyledLink>
            <StyledLink to='/#teachers' style={{margin: 'auto 15px'}}>
              <FormattedMessage
                            id = "navigation.3.button"
                            defaultMessage="Teachers"
              />
            </StyledLink>
            <StyledLink to='/#feedback' style={{margin: 'auto 15px'}}>
              <FormattedMessage
                            id = "navigation.4.button"
                            defaultMessage="Feedbacks"
              />
            </StyledLink>
            <StyledLink to='/contacts' style={{margin: 'auto 15px'}}>
              <FormattedMessage
                            id = "navigation.5.button"
                            defaultMessage="Contacts"
              />
            </StyledLink>
        
          <Item>
            <LangBar/>
          </Item>
        </Menu>
        <Icon>
        <NavIcon onClick={() => toggleNav(!toggle) }>
            <Line  open={toggle} />
            <Line  open={toggle} />
            <Line  open={toggle} />
          </NavIcon>
        <LangBar/>
          {/* <LangSelect>
          <select value = {context.locale} onChange={context.selectLanguage}>
          <option value= 'lv'>LV</option>
          <option value= 'ru'>RU</option>
          <option value= 'en'>EN</option>
        </select>
          </LangSelect> */}
        </Icon>
        
      </Nav>
      <Overlay open={toggle}>
        <OverlayMenu open={toggle}>
        <Item>
              <StyledLink to='/#education-for' onClick={() => {toggleNav(!toggle)}}>
                <FormattedMessage
                  id = "navigation.1.button"
                  defaultMessage="Home"
                />
              </StyledLink>
          </Item>
          <Item>
            <StyledLink to='/#faq' onClick={() => {toggleNav(!toggle)}}>
            <FormattedMessage
                   id = "navigation.2.button"
                   defaultMessage="FAQ"
                />
            </StyledLink>
          </Item>
          <Item>
            <StyledLink to='/#teachers' onClick={() => {toggleNav(!toggle)}}>
            <FormattedMessage
                   id = "navigation.3.button"
                   defaultMessage="Teachers"
                />
            </StyledLink>
          </Item>
          <Item>
            <StyledLink to='/#feedback' onClick={() => {toggleNav(!toggle)}}>
            <FormattedMessage
                  id = "navigation.4.button"
                  defaultMessage="Feedbacks"
                />
            </StyledLink>
          </Item>
          <Item>
            <StyledLink to='/contacts' onClick={() => {toggleNav(!toggle)}}>
            <FormattedMessage
                  id = "navigation.5.button"
                  defaultMessage="Contacts"
                />
            </StyledLink>
          </Item>
        </OverlayMenu>
      </Overlay>
      <Outlet />
    </>
  );
};

export default Navigation;