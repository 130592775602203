import React from 'react';
import {FormattedMessage} from 'react-intl';

import Logo from '../img/companyLogo.png';
import { FiFacebook, FiInstagram, FiMail,FiPhone } from 'react-icons/fi';
import { FaFacebookMessenger, FaWhatsapp } from 'react-icons/fa';
import { BsGeoAlt } from 'react-icons/bs';
export default function Footer() {
  return (
      <>
    <div className='footer'>
       <div className="logo">
            <img src={Logo} alt="" />
            <div className="logo__text">
            <div className="logo__text__title">
                <FormattedMessage id = "footer.logo.subtitle1"/>
            </div>
            <div className="logo__text__subtitle">
                <FormattedMessage id = "footer.logo.title1"/>
            </div>
            <div className="logo__text__title">
                <FormattedMessage id = "footer.logo.subtitle2"/>
            </div>
            </div>
        </div> 
        <div className="info">
            <div className="socials">
                <a href='https://www.facebook.com/matematikadljavseh/' rel="noreferrer" target="_blank" className="facebook"> <FiFacebook /></a>
                <a href='https://www.instagram.com/matematika_dlja_vseh/' rel="noreferrer" target="_blank" className="instagram"><FiInstagram/></a>
                <a href='https://m.me/matematikadljavseh' rel="noreferrer" target="_blank" className="messenger"><FaFacebookMessenger/></a>
                <a href='https://api.whatsapp.com/send?phone=37126774041&text=Labdien!' rel="noreferrer" target="_blank" className="whatsapp"><FaWhatsapp/></a>
            </div>
            <div className="personalInfo">
                <a href="tel:+37126774041" rel="noreferrer" target="_blank" className="phone"><FiPhone/> 26 774 041</a>
                <a href="mailto:matematikavisiem@gmail.com" rel="noreferrer" target="_blank" className="mail"><FiMail/>matematikavisiem@gmail.com</a>
                <a href="https://goo.gl/maps/Pm3RjD5F7kTvPFu59" rel="noreferrer" target="_blank" className="phone"><BsGeoAlt/>Ģertrūdes iela 33/35</a>
            </div>
        </div>
    </div>
    <div className="copyright">
        <FormattedMessage
            id = "footer.logo.copyright"
            defaultMessage="© Все права защищены 2024"
        />
    </div>
    </>
  )
}