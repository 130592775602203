import React, {useEffect} from "react";
import Form from "../components/Form";
import {FormattedMessage} from 'react-intl';
import Footer from "../components/Footer";

const Contacts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div className="contacts">
      <div className="contacts__intro">
        <div className="contacts__intro__title">
        <FormattedMessage id = "contacts.intro.title1" defaultMessage="Записаться к нам"/>
          <span className='contacts__intro__title--yellow'><FormattedMessage id = "contacts.intro.title2" defaultMessage=" на занятие"/></span>
        </div>
      </div>
        <div className="container">
            <Form/>
        </div>
        <Footer/>
    </div>
  
  );
};
export default Contacts;