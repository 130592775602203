import React, { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios, { all } from 'axios';
import Logo from '../img/companyLogoWithoutName.png';
import { FormattedMessage } from 'react-intl';

export default function Form() {
  const [hide, setHide] = useState(false);
  const [info, setInfo] = useState({});
  const [hideTitle, setHideTitle] = useState(true);
  const { register, handleSubmit, setError, clearErrors, reset, formState: { errors }, getValues } = useForm({
    mode: 'onChange',
    criteriaMode: "all"
  });

  function formatText(text) {
    let todayDate = new Date()
    let time = String(todayDate.getDate()).padStart(2, "0") + "." + String((todayDate.getMonth() + 1)).padStart(2, "0") + "." + todayDate.getFullYear() + " " + String(todayDate.getHours()).padStart(2, "0") + ":" + String(todayDate.getMinutes()).padStart(2, "0") + ":" + String(todayDate.getSeconds()).padStart(2, "0");
    let programs = "";
    let lessonType = "";
    let lessonLanguage = "";
    if (text.maths) { programs += "Математика \n" };
    if (text.physic) { programs += "Физика \n" };
    if (text.chemistry) { programs += "Химия \n" };
    if (text.english) { programs += "Английский \n" };
    if (text.latvian) { programs += "Латышский \n" };
    if (text.russian) { programs += "Русский" };
    if (text.it) { programs += "Программирование" };
    if (text.lessonType) { lessonType = text.lessonType };
    if (text.lessonLanguage) { lessonLanguage = text.lessonLanguage };
    return (`
  ⚠️   Новая заявка   ⚠️ 
  ⏰ ${time}
  👵Родитель: ${text.parentName} 
  👶Ученик: ${text.studentName}
  📚Тип урока: ${lessonType} 
  🔤Язык: ${lessonLanguage} 
  ☎️ ${text.phone} 
  📍 ${text.program} 
  🎓 ${programs}
  ℹ️ ${text.text}`)
  }

  function sendMessageAPI(text) {
    axios({
      method: 'post',
      url: process.env.REACT_APP_TELEGRAM_URL,
      data: {
        method: 'sendMessage',
        chat_id: process.env.REACT_APP_TELEGRAM_CHAT_ID,
        text: text,
        parse_mode: 'HTML'
      }
    })
      .then(function (response) {
      })
      .catch(function (error) {
      });
  }


  const onSubmit = (data) => {
    let time = Date().toLocaleString().toString().substr(4, 20);
    setHide(!hide)
    setHideTitle(!hideTitle)
    setInfo(data)
    window.scrollTo({ top: 70, behavior: 'smooth' });
    sendMessageAPI(formatText(data));
    axios.post('https://sheet.best/api/sheets/428e167a-879a-4d6b-b1d4-4a6692ef3351', data).then((response) => {
      console.log(response);
    })
  }
  const validateCheckboxGroup = () => {
    const { maths, physic, chemistry, english, latvian, russian, it } = getValues();
    return maths || physic || chemistry || english || latvian || russian || it || "Выберите хотя бы один предмет";
  };

  const validateNameAndSurname = (value) => {
    if (!value) {
        return "Это поле обязательно для заполнения";
    }

    if (value.trim().length < 7) {
        return "Введите как минимум 3 символа, пробел и еще как минимум 3 символа";
    }

    const regex = /^[^\s]{3,}\s[^\s]{3,}\s?$/; // Паттерн для проверки: минимум 3 символа, пробел, еще минимум 3 символа, опциональный пробел в конце
    if (!regex.test(value.trim())) { 
        return "Введите как минимум 3 символа, пробел и еще как минимум 3 символа";
    }

    return true;
};

  return (
    <div className="form">
      {!hideTitle &&
        <div className="thanks-content">
          <FormattedMessage id="contacts.thanks1" defaultMessage="Спасибо!" /><br />
          <FormattedMessage id="contacts.thanks2" defaultMessage="Ваша заявка была успешна отправлена." /><br />
          <FormattedMessage id="contacts.thanks3" defaultMessage="Свяжемся с Вами по указанному телефону " /><b>{info.phone}</b><FormattedMessage id="contacts.thanks4" defaultMessage=" в течении 2 рабочих дней!" /><br />
          <img src={Logo} alt="" />
        </div>
      }

      {!hide && <form onSubmit={handleSubmit(onSubmit)}>
        <h2></h2>
        {/* <h2>Contact Form</h2> */}
        <label className='title'><FormattedMessage id="form.title1" defaultMessage="Родителя Имя Фамилия*" /></label>
        <input className={`${errors.parentName ? 'error height-50' : 'height-50'}`} {...register("parentName", { validate: validateNameAndSurname })} />
        <p className='form__error'>
          {errors.parentName && <p><FormattedMessage id="form.errorMessage2" defaultMessage="Поле обязательно для заполнения в формате: Имя Фамилия" /></p>}
        </p>
        
        <label className='title'><FormattedMessage id="form.title2" defaultMessage="Ученика Имя Фамилия*" /></label>
        <input className={`${errors.studentName ? 'error height-50' : 'height-50'}`} {...register("studentName", { validate: validateNameAndSurname })} />
        <p className='form__error'>
          {errors.studentName && <p><FormattedMessage id="form.errorMessage2" defaultMessage="Поле обязательно для заполнения в формате: Имя Фамилия" /></p>}
        </p>

        <label className='title'><FormattedMessage id="form.title3" defaultMessage="Телефон*" /></label>
        <input className={`${errors.phone ? 'error height-50' : 'height-50'}`} type="tel" {...register("phone", { required: true, minLength: 8, maxLength: 15 })} />
        <p className='form__error'>{errors.phone && <FormattedMessage id="form.errorMessage1" defaultMessage="Поле обязательно для заполнения" />}</p>

        <label className='title'><FormattedMessage id="form.title4" defaultMessage="Тип урока*" /></label>
        {/* <input type="radio" value="dor" {...register("hello")} /> */}
        <div className="radio__lesson_type">
          <p className='form__error'>{errors.lessonType?.type === 'required' && <FormattedMessage id="form.errorMessage1" defaultMessage="Поле обязательно для заполнения" />}</p>
          <label>
            <input
              {...register("lessonType", { required: true })}
              name="lessonType"
              value="Онлайн"
              type="radio"
            />
            <span className='checkbox__label'><FormattedMessage id="form.type1" defaultMessage="Онлайн" /></span>
          </label>
          <label>
            <input
              {...register("lessonType", { required: true })}
              name="lessonType"
              value="Очно"
              type="radio"
            />
            <span className='checkbox__label'><FormattedMessage id="form.type2" defaultMessage="Очно" /></span>
          </label>
          <label>
            <input
              {...register("lessonType", { required: true })}
              name="lessonType"
              value="Очно/Онлайн"
              type="radio"
            />
            <span className='checkbox__label'><FormattedMessage id="form.type3" defaultMessage="Очно или дистанционно" /></span>
          </label>
          {/* <div className="radio__lesson_type-item">
                <input type="radio" value="Online" className={`${errors.lessonType ? 'error' : ''}`} {...register("lessonType", {required: true })} /> 
                <label>Online</label>
              </div>
              <div className="radio__lesson_type-item">
                <input type="radio" value="inclass" className={`${errors.lessonType ? 'error' : ''}`} {...register("lessonType", {required: true })} /> 
                <label>In Class</label>
              </div> */}
        </div>
        {/* <p className='form__error'>{errors.lessonType?.type === 'required' && "Lesson type is required"}</p> */}

        <label className='title'><FormattedMessage id="form.title45" defaultMessage="На каком языке урок?*" /></label>
        <p className='form__error'>{errors.lessonLanguage?.type === 'required' && <FormattedMessage id="form.errorMessage1" defaultMessage="Поле обязательно для заполнения" />}</p>
        <div className="radio__lesson_language">
          <label>
            <input
              {...register("lessonLanguage", { required: true })}
              name="lessonLanguage"
              value="Русский"
              type="radio"
            />
            <span className='checkbox__label'><FormattedMessage id="form.language1" defaultMessage="Билингвально (RUS/LV)" /></span>
          </label>
          <label>
            <input
              {...register("lessonLanguage", { required: true })}
              name="lessonLanguage"
              value="Латышский"
              type="radio"
            />
            <span className='checkbox__label'><FormattedMessage id="form.language2" defaultMessage="На латышском (LV)" /></span>
          </label>
          <label>
            <input
              {...register("lessonLanguage", { required: true })}
              name="lessonLanguage"
              value="Английский"
              type="radio"
            />
            <span className='checkbox__label'><FormattedMessage id="form.language3" defaultMessage="На английском (EN)" /></span>
          </label>
        </div>

        <label className='title'><FormattedMessage id="form.title5" defaultMessage="Класс*" /></label>
        <div className="custom-select">
          <select {...register("program")}>
            <option value="Дошкольник (5 лет)"><FormattedMessage id="form.option1" defaultMessage="Дошкольник (5 лет)" /></option>
            <option value="Дошкольник (6 лет)"><FormattedMessage id="form.option2" defaultMessage="Дошкольник (6 лет)" /></option>
            <option value="1 класс"><FormattedMessage id="form.option3" defaultMessage="1 класс" /></option>
            <option value="2 класс"><FormattedMessage id="form.option4" defaultMessage="2 класс" /></option>
            <option value="3 класс"><FormattedMessage id="form.option5" defaultMessage="3 класс" /></option>
            <option value="4 класс"><FormattedMessage id="form.option6" defaultMessage="4 класс" /></option>
            <option value="5 класс"><FormattedMessage id="form.option7" defaultMessage="5 класс" /></option>
            <option value="6 класс"><FormattedMessage id="form.option8" defaultMessage="6 класс" /></option>
            <option value="7 класс"><FormattedMessage id="form.option9" defaultMessage="7 класс" /></option>
            <option value="8 класс"><FormattedMessage id="form.option10" defaultMessage="8 класс" /></option>
            <option value="9 класс"><FormattedMessage id="form.option11" defaultMessage="9 класс" /></option>
            <option value="10 класс"><FormattedMessage id="form.option12" defaultMessage="10 класс" /></option>
            <option value="11 класс"><FormattedMessage id="form.option13" defaultMessage="11 класс" /></option>
            <option value="12 класс"><FormattedMessage id="form.option14" defaultMessage="12 класс" /></option>
            <option value="Студент"><FormattedMessage id="form.option15" defaultMessage="Студент" /></option>
          </select>
        </div>
        <label className='title'><FormattedMessage id="form.title6" defaultMessage="Предмет/ы которые хочет изучать ребёнок?*" /></label>
        <p className='form__error'>{errors?.lesson?.type === "custom" && <FormattedMessage id="form.errorMessage1" defaultMessage="Поле обязательно для заполнения" />}</p>
        <div className='form__error'>
          {(errors.maths || errors.physic || errors.chemistry || errors.english || errors.latvian || errors.russian || errors.it) && <p><FormattedMessage id="form.errorMessage1" defaultMessage="Поле обязательно для заполнения" /></p>}
        </div>
        
        <div className="checkContent">
          <label>
            <input
              {...register("maths", { validate: validateCheckboxGroup })}
              name="maths"
              type="checkbox"
            />
            <span className='checkbox__label'><FormattedMessage id="form.lesson1" defaultMessage="Математика" /></span>
          </label>
        </div>


        <div className="checkContent">
          <label>
            <input
              {...register("physic", { validate: validateCheckboxGroup })}
              name="physic"
              type="checkbox"
            />
            <span className='checkbox__label'><FormattedMessage id="form.lesson2" defaultMessage="Физика" /></span>
          </label>
        </div>

        <div className="checkContent">
          <label>
            <input
              {...register("chemistry", { validate: validateCheckboxGroup })}
              name="chemistry"
              type="checkbox"
            />
            <span className='checkbox__label'><FormattedMessage id="form.lesson3" defaultMessage="Химия" /></span>
          </label>
        </div>

        <div className="checkContent">
          <label>
            <input
              {...register("english", { validate: validateCheckboxGroup })}
              name="english"
              type="checkbox"
            />
            <span className='checkbox__label'><FormattedMessage id="form.lesson4" defaultMessage="Английский язык" /></span>
          </label>
        </div>

        <div className="checkContent">
          <label>
            <input
              {...register("latvian", { validate: validateCheckboxGroup })}
              name="latvian"
              type="checkbox"
            />
            <span className='checkbox__label'><FormattedMessage id="form.lesson5" defaultMessage="Латышский язык" /></span>
          </label>
        </div>

        <div className="checkContent">
          <label>
            <input
              {...register("russian", { validate: validateCheckboxGroup })}
              name="russian"
              type="checkbox"
            />
            <span className='checkbox__label'><FormattedMessage id="form.lesson6" defaultMessage="Русский язык" /></span>
          </label>
        </div>
        <div className="checkContent">
          <label>
            <input
              {...register("it", { validate: validateCheckboxGroup })}
              name="it"
              type="checkbox"
            />
            <span className='checkbox__label'><FormattedMessage id="form.lesson11" defaultMessage="Программирование" /></span>
          </label>
        </div>
        {errors.checkboxGroup && <p>{errors.checkboxGroup.message}</p>}
        <label className='title'><FormattedMessage id="form.title7" defaultMessage="Дополнительная информация" /></label>
        <textarea {...register("text")} />
        <FormattedMessage id="form.sendMessage">
          {(label) =>
            <input type="submit" value={label} />
          }
        </FormattedMessage>

      </form>}
    </div>
  );

}
